<template>
  <v-dialog
    v-model="visible"
    scrollable
    max-width="750px"
    hide-overlay
    persistent
    no-click-animation
  >
    <v-card
      v-if="lineItemRecord"
      ref="draggableModal"
      class="draggable-dialog"
      max-height="60%"
      max-width="70%"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-card-title>
        <v-row class="px-3 py-2">
          <div>
            <div class="darkblue--text subtitle-2">
              {{ lineItemRecord.lineItemName }}
            </div>

            <div class="coolgray--text caption">
              <span class="font-weight-bold pr-1">ID</span>
              {{ lineItemRecord.lineItemId }}
            </div>
          </div>

          <v-spacer />

          <div>
            <v-btn
              class="px-3 mx-3"
              color="coolgray"
              icon
              :ripple="false"
              title="Export"
              @click.stop="exportReport(lineItemRecord)"
            >
              <v-icon>
                system_update
              </v-icon>
            </v-btn>

            <v-btn
              :ripple="false"
              icon
              @click.stop="close()"
            >
              <v-icon color="midgray">
                close
              </v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text>
        <div
          v-if="isLoadingLineItemHistory"
          class="loader"
        >
          <v-progress-circular
            :size="70"
            color="#055289"
            indeterminate
          />
        </div>

        <div
          v-else-if="hasError"
          class="error-message"
        >
          <v-icon>error_outline</v-icon>
          Failed to load line item's historical data. Please try again later or
          <a
            href="mailto:tech@oao.freshdesk.com"
            target="_blank"
          >
            contact us
          </a>.
        </div>

        <div
          v-else-if="!hasRows"
          class="empty-state"
        >
          No 10-day historical data could be found for the selected line item.
        </div>

        <table v-else>
          <thead>
            <th
              v-for="(column, colIndex) in exportColumns"
              :key="colIndex"
              :class="`${column.class} py-2`"
            >
              {{ column.columnName }}
            </th>
          </thead>

          <tbody>
            <tr
              v-for="historyItem of lineItemHistory"
              :key="historyItem.lineItemDeliveryId"
            >
              <td
                v-for="(column, colIndex) in exportColumns"
                :key="colIndex"
                :class="typeof column.class === 'function' ? column.class(historyItem) : column.class"
              >
                {{ column.columnValue(historyItem) }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'underscore'
import { createNamespacedHelpers } from 'vuex'

import draggable from 'src/utils/draggable'
import format from 'src/utils/format'
import transform from 'src/utils/transform'

import osi from '../../store/utils/osi'

const { mapActions, mapState } = createNamespacedHelpers('delivery')
const { mapActions: mapToolsActions, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'LineItemHistoryModal',
  props: {
    lineItemRecord: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    format,
    osi,
    dragger: {},
    exportFileName: 'historical',
  }),
  computed: {
    visible: {
      get() {
        return this.isLineItemHistoryModalVisible
      },
      set(value) {
        this.$store.commit('delivery/isLineItemHistoryModalVisible', value)
      },
    },
    ...mapState({
      isLineItemHistoryModalVisible: (state) => state.isLineItemHistoryModalVisible,
      isLoadingLineItemHistory: (state) => state.isLoadingLineItemHistory,
      didLoadLineItemHistoryFail: (state) => state.didLoadLineItemHistoryFail,
      lineItemHistory: (state) => _.sortBy(state.lineItemHistory, 'date').reverse(),
    }),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    isDataReady() {
      const { didLoadLineItemHistoryFail, isLoadingLineItemHistory } = this
      const noLongerLoading = isLoadingLineItemHistory === false
      const didNotFail = didLoadLineItemHistoryFail === false

      return noLongerLoading && didNotFail
    },
    hasRows() {
      const { isDataReady, lineItemHistory } = this
      const { length } = lineItemHistory

      return isDataReady && length > 0
    },
    hasError() {
      const { didLoadLineItemHistoryFail, lineItemHistory } = this
      const invalidReport = lineItemHistory && !Array.isArray(lineItemHistory)

      return didLoadLineItemHistoryFail || invalidReport
    },
    exportColumns() {
      return [
        {
          align: 'start',
          class: 'date',
          columnName: 'Date',
          key: 'date',
          sortable: true,
          columnValue(item) {
            return format.date(item.date)
          },
        },
        {
          align: 'start',
          class: 'impressions',
          columnName: 'Imps',
          key: 'impressions',
          sortable: true,
          columnValue(item) {
            return format.number(item.impressions)
          },
        },
        {
          align: 'start',
          class: 'viewable-impressions',
          columnName: 'Viewable Imps',
          key: 'viewableImpressions',
          sortable: true,
          columnValue(item) {
            return format.number(item.viewableImpressions)
          },
        },
        {
          align: 'start',
          class: 'viewability',
          columnName: 'Viewability',
          key: 'viewabilityPercentage',
          sortable: true,
          columnValue(item) {
            return format.percentage(item.viewabilityPercentage)
          },
        },
        {
          align: 'start',
          class: 'clicks',
          columnName: 'Clicks',
          key: 'clicks',
          sortable: true,
          columnValue(item) {
            return format.number(item.clicks)
          },
        },
        {
          align: 'start',
          class: 'ctr',
          columnName: 'CTR',
          key: 'ctr',
          sortable: true,
          columnValue: (item) => format.percentage(item.ctr),
        },
        {
          align: 'start',
          class: (item) => {
            const value = item ? transform.getOsiView(item.type, item.booked, item.osi) : null
            return osi.getOsiClassName(value)
          },
          columnName: 'OSI',
          key: 'osi',
          sortable: true,
          columnValue(item) {
            return transform.getOsiView(item.type, item.booked, item.osi)
          },
        },
      ]
    },
  },
  watch: {
    lineItemRecord(newValue) {
      if (newValue) {
        const { reportId, subId } = this
        const params = { ...newValue, publisherId: reportId, teamId: subId }

        this.get10DayHistory(params)
      }
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    close() {
      this.visible = false
    },
    ...mapActions(['get10DayHistory']),
    ...mapToolsActions(['exportAs']),
    ...draggable,
    exportReport(lineItemRecord) {
      const { lineItemId } = lineItemRecord
      const { exportColumns, lineItemHistory, exportFileName, storedItems: exportItems } = this
      const exportGroups = [{ rows: lineItemHistory }]
      const exportName = `${exportFileName}_for_${lineItemId}`
      const exportFormat = 'csv'

      return this.exportAs({ exportColumns, exportFormat, exportGroups, exportName, exportItems })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~src/styles/variables";

  .draggable-dialog {
    border-radius: 6px;
    border-width: 0;
    left: 15%;
    position: fixed;
    top: 69px;
  }
  ::v-deep.v-card__title {
    color: $color-monochrome-darkestblue;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.51px;
    line-height: 22px;
    justify-content: space-between;
  }

  ::v-deep.v-card__text {
    padding: 0;
  }

  table {
    border: 1px solid #F2EFEF;
    border-spacing: 0;
    width: 100%;
  }

  th {
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    text-transform: uppercase;
    border-bottom: 1px solid #F2EFEF;
    background: $color-monochrome-white;

    color: #515151;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.21px;
    line-height: 11px;

    text-align: left;
    padding-right: 8px;

    &:first-of-type {
      padding-left: 24px;
    }
  }

  tbody tr {
    height: 60px;

    &:nth-child(odd) {
      background-color: #FBFBFC;
    }
  }

  td {
    color: $color-monochrome-gray;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    line-height: 13px;
    padding-right: 24px;

    &:first-of-type {
      padding-left: 24px;
    }
  }

  td.osi {
    font-weight: bold;

    &.lte-96 {
      color: $color-legend-red;
    }

    &.range-97-99 {
      color: $color-legend-yellow;
    }

    &.range-100-125 {
      color: $color-legend-green;
    }

    &.gte-126 {
      color: $color-legend-blue;
    }
  }
</style>
